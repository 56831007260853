import React from 'react';

import * as styles from './field-error.module.scss';

/**
 * Renders error text in a consistent style for form fields
 */
const FieldError = ({ children }: FieldErrorProps) => {
  return <small className={styles.FieldError}>{children}</small>;
};

export interface FieldErrorProps {
  children: React.ReactNode;
}

export default FieldError;
