import React from 'react';
import { navigate, PageProps } from 'gatsby';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';

import * as styles from './account.module.scss';
import Spinner from '../components/spinner';
import Button from '../components/button';
import Page, { PageBody, PageHeader } from '../components/page';
import useAPIHelper from '../hooks/api';
import useSubscriptionContext from '../hooks/subscription-context';
import toast from '../utils/toast';
import { pagePath } from '../constants/routes';
import { UnsubscribeAction } from '@common/types/user';
import { AccountFormValues } from '@common/types/form';
import { TEAM_ID_VALIDATION } from '@common/constants/form';
import AccountForm, { AccountPageState } from '../components/account-form';

const accountFormSchema = object({
  email: string().email('A valid email address is required').required(),
});

const teamAdminFormSchema = object({
  teamId: string()
    .trim()
    .required('Team ID is required for Team Plan features')
    .min(TEAM_ID_VALIDATION.MIN, 'Please enter an ID 3-16 characters long')
    .max(TEAM_ID_VALIDATION.MAX, 'Please enter an ID 3-16 characters long')
    .matches(
      TEAM_ID_VALIDATION.SCHEMA,
      'Invalid format. Supports lowercase letters, numbers, hyphens and underscores only.'
    ),
  teamName: string().required(),
});

// TODO: This page is awaiting design/content
const AccountPage = ({
  location,
}: PageProps<unknown, unknown, AccountPageState>) => {
  const { isLoading, loginWithRedirect, user } = useAuth0();
  const {
    hasFormErrors,
    makeFetchLambdaPrivateConfig,
    useLambdaAPI,
  } = useAPIHelper();
  const {
    status,
    subscriptionCancelledAt,
    subscriptionEnd,
    currentPlan,
    isActive,
    teamId,
    teamName,
    isLoading: isUserDataLoading,
  } = useSubscriptionContext();

  const isTeamAdmin = isActive && currentPlan === 'TEAM';

  const [
    { data: accountData, loading: isAccountLoading },
    fetchAccount,
  ] = useLambdaAPI<AccountFormValues>(
    { url: '/update-user', method: 'PATCH' },
    { manual: true }
  );

  const [
    // TODO: Implement subscription loading as spinner
    { data: subscriptionData, loading: isSubscriptionLoading },
    fetchSubscription,
  ] = useLambdaAPI({ url: '/unsubscribe', method: 'PATCH' }, { manual: true });

  if (!user || isUserDataLoading)
    return <Spinner isLoading={isLoading || isUserDataLoading} />;

  const statusLabel = `Your subscription is ${status}`;
  const planLabel = `You're subscribed to the ${currentPlan} plan`;

  const renewalDate = new Date(
    (subscriptionEnd as number) * 1000
  ).toUTCString();

  const cancellationDate = subscriptionCancelledAt
    ? new Date(subscriptionCancelledAt * 1000).toUTCString()
    : null;

  /**
   * Handles form submit if valid. Calls the lambda API to update values on the
   * users account. Sets the new values on success, otherwise sets errors.
   */
  const handleSaveAccount = async (
    values: AccountFormValues,
    { setFieldError }: FormikHelpers<AccountFormValues>
  ) => {
    try {
      await fetchAccount(
        await makeFetchLambdaPrivateConfig({
          data: values,
          method: 'PATCH',
        })
      );

      toast.success('Saved changes!');

      // Force re-auth to avoid data/session issues
      await loginWithRedirect({
        appState: { returnTo: pagePath.account },
      });
    } catch (error) {
      if (hasFormErrors(error)) {
        const { formErrors } = error.response.data;

        setFieldError('teamId', formErrors.teamId);
        setFieldError('teamName', formErrors.teamName);

        toast.error(Object.values(formErrors).join(', '));
      }
    }
  };

  // TODO: Account delete action
  const handleDeleteAccountClick = () => console.log('deleting account');

  const handleUnsubscribeClick = async (
    unsubscribeAction: UnsubscribeAction
  ) => {
    try {
      await fetchSubscription(
        await makeFetchLambdaPrivateConfig({
          data: { unsubscribeAction },
          method: 'PATCH',
        })
      );

      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error('Could not cancel subscription');
    }
  };

  return (
    <Page>
      <PageHeader location={location} />

      <PageBody>
        <div className={styles.Account__body}>
          <Formik
            initialValues={
              {
                email: user.email,
                teamId,
                teamName,
              } as AccountFormValues
            }
            onSubmit={(values, formikHelpers) =>
              handleSaveAccount(values, formikHelpers)
            }
            validationSchema={
              isTeamAdmin
                ? accountFormSchema.concat(teamAdminFormSchema)
                : accountFormSchema
            }
          >
            <>
              <AccountForm location={location} />

              <article className={styles.Account__card}>
                <div className={styles.Account__cardBody}>
                  <h2>Subscription</h2>

                  {isActive ? (
                    <>
                      <h3>Status</h3>
                      <p>{statusLabel}</p>

                      <h3>Plan</h3>
                      <p>{planLabel}</p>

                      <h3>Next renewal</h3>
                      <p>
                        {status === 'WILL_CANCEL'
                          ? `You cancelled your renewal on ${cancellationDate}. Your subscription will expire on ${renewalDate}.`
                          : renewalDate}
                      </p>
                    </>
                  ) : status === 'EXPIRED' ? (
                    `You don't have an active subscription. Your ${currentPlan} subscription expired on ${renewalDate}.`
                  ) : status === 'CANCELLED' ? (
                    `You don't have an active subscription. Your ${currentPlan} subscription expired on ${renewalDate} after being cancelled on ${cancellationDate}.`
                  ) : (
                    `You don't have a subscription. Sign up!`
                  )}
                </div>

                <footer className={styles.Account__cardActions}>
                  {isActive ? (
                    <>
                      <Button
                        isDisabled={status === 'WILL_CANCEL'}
                        isBlock
                        onClick={() => handleUnsubscribeClick('PERIOD_END')}
                      >
                        Cancel renewal
                      </Button>

                      <Button
                        isBlock
                        onClick={() => handleUnsubscribeClick('IMMEDIATE')}
                      >
                        Cancel immediately
                      </Button>
                    </>
                  ) : (
                    <Button isBlock onClick={() => navigate(pagePath.pricing)}>
                      Sign up
                    </Button>
                  )}
                </footer>
              </article>
            </>
          </Formik>
        </div>
      </PageBody>

      <Spinner isLoading={isAccountLoading || isSubscriptionLoading} />
    </Page>
  );
};

export default withAuthenticationRequired(AccountPage, {
  returnTo: pagePath.account,
});
